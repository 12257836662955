import React from "react"
import { TextField } from "@material-ui/core"
import { useField } from "formik"

const TextfieldWrapper = ({
  name,
  ...otherProps
}) => {
  const [field, mata] = useField(name)

  const configTextfield = {
    ...field,
    variant: 'outlined',
    fullWidth: true,
    ...otherProps
  }

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true
    configTextfield.helperText = mata.error
  }

  return (
    <TextField {...configTextfield} />
  )
}

export default TextfieldWrapper