import React, { useState } from "react"
import { graphql } from "gatsby"
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { Formik, Form } from "formik"
import * as Yup from 'yup'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MuiTypography from '@material-ui/core/Typography'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'

import Header from "../components/Header"
import Seo from './seo'
import Layout from '../layout/global'

import Mdx from '../components/Mdx'

import MuiSelect from '../components/Form/Select'
import MuiTextfield from '../components/Form/Textfield'
import MuiButton from '../components/Form/Button'
import services from '../data/services.json'
// import reason from '../data/reason.json'
// import who from '../data/who.json'
// import lang from '../data/language.json'

import * as designSystem from '../components/DesignSystem'
import * as globalCls from './../layout/Global.module.css'

const pageTitle = "Contact Us | Captain Bob Constructions"
const pageDescription = 'Any enquiries for Captain Bob Constructions can be sent through the website contact form.'

const INITIAL_FORM_STATE = {
	firstName: '',
	lastName: '',
	mobile: '',
	email: '',
	// preferredLanguage: '',
	// reason: '',
	// who: '',
	services: '',
	message: ''
}

// For an example of Form using Material UI, see the following Github example: https://github.com/simpletut/React-Formik-Material-UI-Booking-Form-Template
// Video tutorial of example can be found at: https://www.youtube.com/watch?v=MV9NC3FoCmM
const FORM_VALIDATION = Yup.object().shape({
	firstName: Yup.string()
		.required('Required'),
	lastName: Yup.string()
		.required('Required'),
	// preferredLanguage: Yup.string()
	// 	.required('Required'),
	// reason: Yup.string()
	// 	.required('Required'),
	// who: Yup.string()
	// 	.required('Required'),
	services: Yup.string()
		.required('Required'),
	mobile: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, "An invalid mobile number has been detected - please try again.")
		.min(10, 'Must be an Australian Mobile number containing 10 digits (excluding country code)')
		.max(10, 'Must be an Australian Mobile number containing 10 digits (excluding country code)'),
	email: Yup.string()
		.email('An invalid email address has been detected - please try again.')
		.required('Required'),
	message: Yup.string()
})

const theme = {
	primary: {
		main: '#024965',
		light: '#0098d4',
		dark: '#002737'
	},
	grey: {
		main: 'rgba(128, 128, 128, 0.6)',
		light: '#e2e2e2c0',
		dark: '#646568'
	},
	black: 'black',
	white: 'white'
}
const fontFamilies = ["Nunito Sans, sans-serif, serif"]

// Material UI customisations using v5 (not v4) using Emotion
// library (i.e. CSS styles with Javascript) via 'styled'
const useStyles = makeStyles((theme) => ({
	formWrapper: {
		paddingLeft: 0,
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}))

// For customising textfield styling, see: https://mui.com/material-ui/react-text-field/#customization
// For customising button styling, see: https://mui.com/material-ui/react-button/#customization
const textFieldCustomisations = {
	'& label.Mui-focused': {
		color: theme.primary.main,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: theme.primary.main,
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: theme.grey.main,
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.primary.main,
		}
	},
	'& .MuiInputBase-input': {
		fontFamily: fontFamilies
	}
}
const btnCustomisations = {
	fontFamily: fontFamilies
}

const typographyCustomisations = {
	color: theme.grey.dark,
	fontFamily: fontFamilies,
	fontWeight: 800,
	fontSize: '0.9rem'
}

function submitApi(contents, recaptcha) {
	const { firstName, lastName, email, mobile, services, message } = contents
	const inputs = { firstName, lastName, email, mobile, services, message, recaptcha }
	// console.log(inputs)
	const response = axios({
		method: 'post',
		url: process.env.GATSBY_API_ENDPOINT,
		data: JSON.stringify(inputs),
		headers: {
			'X-Api-Key': process.env.GATSBY_API_KEY,
			'Content-Type': 'application/json'
		}
	})
		.then((response) => {
			// console.log(response)
			return response
		})
		.catch((error) => {
			// console.error(error)
			return {
				status: 502
			}
		})
	return response
}

const ContactForm = () => {

	const [successFlag, setSuccessFlag] = useState(null)
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState(null)

	const [recaptcha, setRecaptcha] = useState('')
	const [recaptchaError, setRecaptchaError] = useState('')
	const handleRecaptchaChange = (value) => setRecaptcha(value)

	function detectEmptyRecaptcha(recaptcha) {
		if (recaptcha === '') {
			setRecaptchaError(true)
			return true
		} else {
			setRecaptchaError(false)
			return false
		}
	}

	function submitForm(contents, recaptcha) {
		const empty = detectEmptyRecaptcha(recaptcha)

		if (!empty) {
			const _response = submitApi(contents, recaptcha)
			_response
				.then((response) => {
					if (response.status === 200) {
						// Load up Success Message
						setSuccessFlag(true)
						setSuccessMessage(
							response
								? response.data.message
								: 'Thanks for submitting your enquiry! We will be in touch with you soon.'
						)
					} else if (response.status === 502) {
						setSuccessFlag(false)
						setErrorMessage(
							"A sneaky monkey must've dozed off when you submitted the form! Please try again."
						)
					} else {
						setSuccessFlag(false)
						setErrorMessage(
							'An error has arose on our end. We apologise for this - please come back to submit later.'
						)
					}
				})
				.catch((error) => {
					// Load up Fail Message
					// console.error(error)
					setSuccessFlag(false)
					setErrorMessage(
						"A sneaky monkey must've dozed off when you submitted the form! Please try again."
					)
				})
		}
	}

	const classes = useStyles()
	const TextField = styled(MuiTextfield)(textFieldCustomisations)
	const Select = styled(MuiSelect)(textFieldCustomisations)
	const Button = styled(MuiButton)(btnCustomisations)
	const Typography = styled(MuiTypography)(typographyCustomisations)

	return (
		<>
			{
				successFlag !== null ? (
					successFlag === true ? (
						<Alert severity="success"><designSystem.shortcodes.p>{successMessage}</designSystem.shortcodes.p></Alert>
					) : (
						<Alert severity="error"><designSystem.shortcodes.p>{errorMessage}</designSystem.shortcodes.p></Alert>
					)
				) : null
			}

			<Grid container>
				<Grid item xs={12}>
					<Container maxWidth="sm">
						<div className={classes.formWrapper}>
							<Formik
								initialValues={{
									...INITIAL_FORM_STATE
								}}
								validationSchema={FORM_VALIDATION}
								onSubmit={(values) => submitForm(values, recaptcha)}
							>
								<Form>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="overline" display="block">
												Your Details
											</Typography>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={6}>
											<TextField
												name="firstName"
												label="First Name"
												placeholder="John"
											/>
										</Grid>

										<Grid item xs={6}>
											<TextField
												name="lastName"
												label="Last Name"
												placeholder="Smith"
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												name="mobile"
												label="Mobile Number"
												placeholder="0412345678"
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												name="email"
												label="Email Address"
												placeholder="john.smith@example.com"
											/>
										</Grid>
									</Grid>

									{/* <Grid container spacing={3}>
										<Grid item xs={12}>
											<Select
												name="preferredLanguage"
												label="What is your Preferred Language? (if any)"
												options={lang}
											/>
										</Grid>
									</Grid> */}

									<br />

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="overline" display="block">
												Your enquiry
											</Typography>
										</Grid>
									</Grid>

									{/* <Grid container spacing={3}>
										<Grid item xs={12}>
											<Select
												name="reason"
												label="What is the reason for your enquiry?"
												options={reason}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Select
												name="who"
												label="Who are you submitting for?"
												options={who}
											/>
										</Grid>
									</Grid> */}

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Select
												name="services"
												label="What service are you interested in?"
												options={services}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												name="message"
												label="Message"
												multiline={true}
												minRows={4}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={12}>
											{
												recaptchaError === true
													?
													(
														<>
															<Alert severity="error">
																<designSystem.shortcodes.p>
																	Please complete the recaptcha.
																</designSystem.shortcodes.p>
															</Alert>
															<br />
														</>
													)
													:
													null
											}
											<ReCAPTCHA
												sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
												onChange={handleRecaptchaChange}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={3}>
										<Grid item xs={4}>
											{
												successFlag === true ?
													<Button disabled size="large">Refresh</Button>
													:
													<Button size="large">Submit</Button>
											}
										</Grid>
									</Grid>
								</Form>
							</Formik>
						</div>
						{
							successFlag === true
								?
								<Alert severity="info">
									<designSystem.shortcodes.p>
										Please refresh the page to submit again.
									</designSystem.shortcodes.p>
								</Alert>
								:
								null
						}
					</Container>
				</Grid>
			</Grid >
		</>
	)
}


const ContactUsPage = ({ data }) => {
	const { allMdx } = data
	const { nodes } = allMdx

	let placeholder

	nodes.map((node) => {
		const { frontmatter, body } = node
		const { page, section } = frontmatter
		if (page === "contactUs" && section === "placeholder") {
			placeholder = body
		}
		return null
	})

	const defaultShortcodes = designSystem.shortcodes

	return (
		<main>
			<Seo title={pageTitle} description={pageDescription} pageUrl='/contact-us/' />
			<Header pageName={pageTitle} pageDescription={pageDescription} pageUrl='/contact-us/' />
			<Layout>
				<section className={`${globalCls.section} ${globalCls.padding}`}>
					<div className={`${globalCls.section__wrapper}`}>
						<Mdx content={placeholder} components={defaultShortcodes} />
						<ContactForm />
					</div>
				</section>

			</Layout>
		</main>
	)
}

export default ContactUsPage

export const pageQuery = graphql`
  	query {
		allMdx {
			nodes {
			  body
			  frontmatter {
				page
				section
			  }
			}
		  }
	}
`
